
/**
 * @name: 交易管理-运费设置
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 交易管理-运费设置
 * @update: 2023-12-07 16:39
 */
import { Vue, Component } from "vue-property-decorator"
// @ts-ignore
import Citys from "@/constants/citys.json"
import { dictDetailApi, dictSetUpApi } from "@/apis/dict"
import type { ICrudOption } from "@/types/m-ui-crud"

@Component({})
export default class saleFee extends Vue {
  Citys = Citys
  // 包邮价编辑状态
  shippingPriceEdit = false
  // 包邮价
  shippingPrice = ''
  // 运费编辑
  feePriceEdit = false
  // 运费
  feePrice = ''
  // 不发货弹窗
  noSendDialog = false
  // 不发货地区
  noSendArr: string[] = []
  // 查看额外收费
  morePriceDialog = false
  // 额外收费编辑
  morePriceEdit = false
  // 额外收费价格
  morePrice = ''
  // 额外收费地区
  moreCityArr: string[] = []

  getDetail (type: number): Promise<string> {
    return new Promise((resolve, reject) => {
      dictDetailApi(type).then(e => {
        resolve(e.value)
      }).catch(() => {
        reject('')
      })
    })
  }

  openEdit (type: number) {
    if (type == 2) {
      this.shippingPriceEdit = true
    } else if (type == 3) {
      this.feePriceEdit = true
    } else if (type == 5) {
      this.morePriceEdit = true
    }
  }

  async closeEdit (type: number) {
    try {
      if (type == 2) {
        this.shippingPrice = (await this.getDetail(2)) || '0'
        this.shippingPriceEdit = false
      } else if (type == 3) {
        this.feePrice = (await this.getDetail(3)) || '0'
        this.feePriceEdit = false
      } else if (type == 5) {
        try {
          const morePriceRes = JSON.parse((await this.getDetail(5)) || '{"price":"0","area":[]}')
          this.morePrice = morePriceRes.price
          this.moreCityArr = morePriceRes.area
        } catch (e) {
          this.morePrice = '0'
          this.moreCityArr = []
        }
        this.morePriceEdit = false
      }
    } catch (e) {
      if (type == 2) {
        this.shippingPrice = '0'
        this.shippingPriceEdit = false
      } else if (type == 3) {
        this.feePrice = '0'
        this.feePriceEdit = false
      } else if (type == 5) {
        this.morePrice = '0'
        this.moreCityArr = []
        this.morePriceEdit = false
      }
    }
  }

  async submit (type: number) {
    const zzsRegex = /^[0-9]*[1-9][0-9]*$/
    let value = ''
    if (type == 2) {
      if (!this.shippingPrice) {
        return this.$message.error("请输入价格")
      }
      if (!zzsRegex.test(this.shippingPrice) || Number(this.shippingPrice || '0') == 0) {
        return this.$message.error("请输入正整数价格")
      }
      if (parseInt(this.shippingPrice) > 999999) {
        return this.$message.error("价格最大不能超过999999")
      }
      value = this.shippingPrice;
    } else if (type == 3) {
      if (!this.feePrice) {
        return this.$message.error("请输入运费")
      }
      if (!zzsRegex.test(this.feePrice) || Number(this.feePrice || '0') == 0) {
        return this.$message.error("请输入正整数运费")
      }
      if (parseInt(this.feePrice) > 999999) {
        return this.$message.error("运费最大不能超过999999")
      }
      value = this.feePrice;
    } else if (type == 5) {
      if (!this.morePrice) {
        return this.$message.error("请输入价格")
      }
      if (!zzsRegex.test(this.morePrice) || Number(this.morePrice || '0') == 0) {
        return this.$message.error("请输入正整数价格")
      }
      if (parseInt(this.morePrice) > 999999) {
        return this.$message.error("价格最大不能超过999999")
      }
      value = JSON.stringify({price: this.morePrice, area: this.moreCityArr})
    }
    await this.updateDict(type, value)
    await this.closeEdit(type)
    this.$message.success('操作成功')
  }

  async updateDict (type: number, value: string) {
    return new Promise(resolve => {
      dictSetUpApi(type, value).then(e => {
        resolve(e)
      })
    })
  }


  /**
   * 打开不发货地区弹窗
   */
  openNoSendDialog () {
    this.noSendDialog = true
    setTimeout(() => {
      // @ts-ignore
      this.$refs.noSendTreeRef.setCheckedKeys(this.noSendArr, true)
    }, 0)
  }


  /**
   * 打开额外收费地区
   */
  openMorePriceDialog () {
    this.morePriceDialog = true
    setTimeout(() => {
      // @ts-ignore
      this.$refs.morePriceTreeRef.setCheckedKeys(this.moreCityArr, true)
    }, 0)
  }

  /**
   * 选择不发货地区确认
   * @param done 完成
   * @param loading 加载
   */
  async handleNoSendEnter (done: Function, loading: Function) {
    let cityCodeArr = []
    // @ts-ignore
    const selectLeafNodes = this.$refs.noSendTreeRef.getCheckedNodes(true, false)
    if (!selectLeafNodes || !selectLeafNodes.length) {
      cityCodeArr = []
    } else {
      cityCodeArr = selectLeafNodes.map((item: any) => item.code)
    }
    try {
      await this.updateDict(4, JSON.stringify(cityCodeArr))
      this.$message.success('操作成功!')
      try {
        this.noSendArr = JSON.parse((await this.getDetail(4)) || '[]')
      } catch (e) {
        this.noSendArr = []
      }
      done()
    } catch (e) {}
    loading()
  }

  /**
   * 选择不发货地区取消
   */
  handleNoSendClose () {

  }

  /**
   * 额外收费地区确认
   * @param done
   * @param loading
   */
  async handleMorePriceEnter (done: Function, loading: Function) {
    let cityCodeArr = []
    // @ts-ignore
    const selectLeafNodes = this.$refs.morePriceTreeRef.getCheckedNodes(true, false)
    if (!selectLeafNodes || !selectLeafNodes.length) {
      cityCodeArr = []
    } else {
      cityCodeArr = selectLeafNodes.map((item: any) => item.code)
    }
    try {
      await this.updateDict(5, JSON.stringify({price: this.morePrice, area: cityCodeArr}))
      this.$message.success('操作成功!')
      try {
        const morePriceRes = JSON.parse((await this.getDetail(5)) || '{"price":"0","area":[]}')
        this.morePrice = morePriceRes.price
        this.moreCityArr = morePriceRes.area
      } catch (e) {
        this.morePrice = '0'
        this.moreCityArr = []
      }
      done()
    } catch (e) {}
    loading()
  }

  /**
   * 额外收费地区关闭
   */
  handleMorePriceClose () {

  }

  async created () {
    try {
      this.shippingPrice = (await this.getDetail(2)) || '0'
      this.feePrice = (await this.getDetail(3)) || '0'
    } catch (e) {
      this.shippingPrice = '0'
      this.feePrice = '0'
    }
    try {
      this.noSendArr = JSON.parse((await this.getDetail(4)) || '[]')
    } catch (e) {
      this.noSendArr = []
    }
    try {
      const morePriceRes = JSON.parse((await this.getDetail(5)) || '{"price":"0","area":[]}')
      this.morePrice = morePriceRes.price
      this.moreCityArr = morePriceRes.area
    } catch (e) {
      this.morePrice = '0'
      this.moreCityArr = []
    }
  }
}
